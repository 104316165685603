export const PAGE_SIZE_XL = 12;
export const PAGE_SIZE_L = 6;
export const PAGE_SIZE_S = 3;

export const RELATED_SECTION_ID = 235;
export const MODELS_SECTION_ID = 280;

export const CART_KEY = 'hellberg_cart';
export const FAVORITE_KEY = 'hellberg_favorites';
export const TIMED_DIALOG_KEY = 'hell_timed_dialog_renew_date';
export const RENEW_INTERVAL = 6 * 30 * 24 * 3600 * 1000; // 6 MONTHS

export const RADIUS_ALTERNATIVES = [25, 50, 100];
export const DEFAULT_RADIUS = RADIUS_ALTERNATIVES[1] ?? 50;
export const TABS = {
  onlineStores: 1,
  search: 0,
};

const LANDING_VIEW = '/';
const PRODUCTS = 'products';
const SEARCH = 'search';
const DOCUMENTS = 'declaration-of-conformity';
const GUIDES = 'guides';
const RETAILERS = 'retailers';
const CONTACT = 'contact';
const CAMPAIGN = 'campaign';

export const routes = {
  CAMPAIGN,
  CONTACT,
  DOCUMENTS,
  GUIDES,
  LANDING_VIEW,
  PRODUCTS,
  RETAILERS,
  SEARCH,
};
