import { createContext, FC } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import {
  addToCart,
  CART_KEY,
  transformProduct,
} from '@hultafors/hellberg/helpers';
import {
  HellbergProduct,
  HellbergProductDetails,
  ParsedProduct,
  SiteLocaleFragment,
} from '@hultafors/hellberg/types';

export interface CartContextValue {
  cart: ParsedProduct[];
  addItemToCart(
    product: HellbergProduct | HellbergProductDetails,
    amount: number
  ): void;
  deleteItemFromCart(e: any, product: ParsedProduct): void;
  deleteCart(): void;
}

export const CartContext = createContext<CartContextValue | null>(null);

export interface CartProviderProps {
  children?: React.ReactNode;
  siteLocale: SiteLocaleFragment;
}

export const CartProvider: FC<CartProviderProps> = ({
  children,
  siteLocale,
}) => {
  const cart = useLocalStorageValue<ParsedProduct[]>(
    `${CART_KEY}-${siteLocale?.parttrapMarket || 'com'}`,
    { defaultValue: [], initializeWithValue: false }
  );

  const addItemToCart = (
    product: HellbergProduct | HellbergProductDetails,
    amount: number
  ) => {
    const parsedProduct: ParsedProduct = transformProduct(product);
    const result = addToCart(cart.value || [], parsedProduct, amount);
    if (result) {
      cart.set(result);
    }
  };

  const deleteCartItem = (e: any, product: ParsedProduct) => {
    e.preventDefault();

    let result: any = [];

    if (cart) {
      result = (cart.value || []).filter((item) => item.sku !== product.sku);
    }
    cart.set(result);
  };

  const deleteCart = () => {
    cart.set([]);
  };

  return (
    <CartContext.Provider
      value={{
        addItemToCart,
        cart: cart.value || [],
        deleteCart,
        deleteItemFromCart: deleteCartItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
